import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { history } from './_helpers';
import { authenticationService } from './_services';
import { PrivateRoute } from './_components';
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
//import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink } from 'apollo-link';

import Uploader from './Components/MyAccount/Uploader';
import LoginPage from './Components/Login/Login';
import ResetPwd from './Components/MyAccount/ResetPwd';
import ContactUs from './Components/MyAccount/CustomerService';
import Faqs from './Components/MyAccount/Faqs';
import PaymentUploader from './Components/MyAccount/PaymentUploader';
import DealerTable from './Components/MyAccount/DealerTable';
import DealerACHUpdate from './Components/MyAccount/DealerACHUpdate';
import DealerPaymentTable from './Components/MyAccount/DealerPaymentTable';
import ChaseACH from './Components/MyAccount/ChaseACH';
import Report from './Components/MyAccount/Report';
import Forgot from './Components/Forgot/Forgot';

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_NODE_URL+':4000/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('currentUser');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});
const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_NODE_URL_WSS}:4000/graphql`,
  options: {
    reconnect: true,
    /*connectionParams: {
      authToken: localStorage.getItem('isAuth'),
    }*/
  }
})

wsLink.subscriptionClient.on('connecting', () => {
  console.log('connecting');
});

wsLink.subscriptionClient.on('connected', () => {
  console.log('connected');
});

wsLink.subscriptionClient.on('reconnecting', () => {
  console.log('reconnecting');
});

wsLink.subscriptionClient.on('reconnected', () => {
  console.log('reconnected');
});

wsLink.subscriptionClient.on('disconnected', () => {
  console.log('disconnected');
});

wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () => wsLink.subscriptionClient.maxConnectTimeGenerator.max;

/*const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  logoutLink.concat(authLink.concat(httpLink))
)*/
const errorlink = onError(({ graphQLErrors, networkError, operation, forward, response }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
    authenticationService.logout();
    history.push('/login');
    window.location='/login';
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    console.log(response);
    //response.errors = null;
    authenticationService.logout();
    history.push('/login');
  }
});

const httpLinkErrHandling = ApolloLink.from([
  errorlink,
  httpLink,
])

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  authLink.concat(httpLinkErrHandling)
)
/*const client = new ApolloClient({
  link: logoutLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache()
});*/
const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache()
});

/*function App() {
  return (
    <ApolloProvider client={client}>
      <React.Fragment>
        <Router>
          <NavigationBar />
          <Jumbotron />
          <Uploader />
        </Router>
      </React.Fragment>
    </ApolloProvider>
  );
}*/
class App extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
          currentUser: null
      };
  }

  componentDidMount() {
      authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
  }

  logout() {
      authenticationService.logout();
      history.push('/login');
  }

  render() {
    const { currentUser } = this.state;
    return (
      <ApolloProvider client={client}>
        <React.Fragment>
          <Router history={history}>
            <PrivateRoute exact path="/uploader" component={Uploader} />
            <PrivateRoute exact path="/" component={Uploader} />
            <PrivateRoute exact path="/resetPwd" component={ResetPwd} />
            <PrivateRoute path="/contactus" component={ContactUs} />
            <PrivateRoute path="/faqs" component={Faqs} />
            <PrivateRoute path="/paymentuploader" component={PaymentUploader} />
            <PrivateRoute path="/dealerlist" component={DealerTable} />
            <PrivateRoute path="/dealerachupdate" component={DealerACHUpdate} />
            <PrivateRoute path="/dealerpaymentlist" component={DealerPaymentTable} />
            <PrivateRoute path="/chaseach" component={ChaseACH} />
            <PrivateRoute exact path="/report" component={Report} />
            <Route path="/login" component={LoginPage} />
            <Route exact path="/forgotpwd" component={Forgot} />
          </Router> 
        </React.Fragment>
      </ApolloProvider>
    );
}
}

export default App;