import React, { useState, useCallback, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useLazyQuery, useSubscription } from "@apollo/react-hooks";
import styled from 'styled-components';
import NavWithRouter from './NavigationBar';
import Jumbotron from './Jumbotron';
import LoadingOverlay from 'react-loading-overlay';
import {
    Form,
    InputNumber,
    Checkbox, Col, Row, DatePicker, Button
  } from 'antd';
import 'antd/dist/antd.css';
//import Download from './Download';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import CheckBoxGrid from './CheckBoxGrid';

const Styles = styled.div`
    .ant-checkbox-group  {
        width: 100%;
    }
    .ant-btn-primary {
        background-color: orange;
    }
`;
const query = gql`
  query retrieveDealerPaymentInformation($startDate: String, $endDate: String, $dealerToken: String, $statuses: String){
    retrieveDealerPaymentInformation(startDate: $startDate, endDate: $endDate, dealerToken: $dealerToken, statuses: $statuses) {
        reportDate
        dealerName
        infoLeaseContractNo
        sfdcId
        cusName
        sysAddress
        contractStatus
        counterSignDate
        fundingSubmissionDate
        depositDate
        contractPrice
        financingFees
        disbursementAmt
        pendingReason
        disposalFlag
        dealerPhone
        dealerId
    }
  }
`;
const Report = (props) => {
    const [spinnerDisplay, setSpinnerDisplay] = useState(false);
    const formItemLayout = {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 24,
        },
      };
    const colLabels = {'reportDate' : 'Last Reporting Date', 'dealerId': 'Dealer ID','dealerName': 'Dealer Name', 'dealerPhone': 'Dealer Phone','infoLeaseContractNo': 'Info Lease Contract No', 'sfdcId': 'Customer Contract No', 
        'cusName':'Customer Name', 'sysAddress': 'Customer Install Address', 'contractStatus': 'Contract Status', 'counterSignDate': 'Contract Counter Signed Date', 
        'fundingSubmissionDate': 'Funding Submission Date', 'depositDate': 'Disbursement Date', 'contractPrice': 'Contract Price', 'financingFees' : 'SunPower Financing Fees (9%)',
    'disbursementAmt': 'Disbursement Amount', 'pendingReason': 'Pending Reason/Notes', 'disposalFlag':'Disposal Flag'};
    const headerOrder = {'reportDate' : 'Last Reporting Date', 'dealerId': 'Dealer ID', 'dealerName': 'Dealer Name', 'dealerPhone': 'Dealer Phone', 'infoLeaseContractNo': 'Info Lease Contract No', 'sfdcId': 'Customer Contract No', 
        'cusName':'Customer Name', 'sysAddress': 'Customer Install Address', 'contractStatus': 'Contract Status', 'counterSignDate': 'Contract Counter Signed Date', 
        'fundingSubmissionDate': 'Funding Submission Date', 'depositDate': 'Disbursement Date', 'contractPrice': 'Contract Price', 'financingFees' : 'SunPower Financing Fees (9%)',
    'disbursementAmt': 'Disbursement Amount', 'pendingReason': 'Pending Reason/Notes', 'disposalFlag':'Disposal Flag'};
    
    const [reportDates, setReportDates] = useState({'sdate': '', "edate": ''})
    let [pullData, {loading, data}] = useLazyQuery(query);
    const fileName = "PaymentReport";
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const [reportCols, setReportCols] = useState({});
    const [statusCols, setStatusCols] = useState({});
    useEffect(() => {
        setTimeout(()=>{
            if (data && !loading && spinnerDisplay) {
                
                //console.log(data);
                let tempRowData = [];
                
                data.retrieveDealerPaymentInformation.map(paymentEntry => {
                    let payment = {};
                    const keys = Object.keys(paymentEntry);
                    //console.log(reportCols);
                    keys.map(colKey => {
                        if (reportCols[colKey]) {
                            payment[colLabels[colKey]] = paymentEntry[colKey];
                        }
                    })
                    tempRowData.push(payment);
                });
                const columnOrder = [];
                
                for (let key in headerOrder) {
                    //console.log(key,':',headerOrder[key]);
                    if (reportCols[key]) {
                        columnOrder.push(headerOrder[key]);
                    }
                }
                
                if (spinnerDisplay && tempRowData.length > 0) {
                    console.log('tempRowData: ',tempRowData);
                    const ws = XLSX.utils.json_to_sheet(tempRowData, {header:columnOrder});
                    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    const data2 = new Blob([excelBuffer], {type: fileType});
                    FileSaver.saveAs(data2, fileName + fileExtension);
                }
                tempRowData = [];
                setSpinnerDisplay(false);
            }
        }, 1000);
    }, [data, loading]);

    const onFinish = values => {
        //console.log(statusCols);
        const statuses = Object.assign({}, ...
            Object.entries(statusCols).filter(([k,v]) => v === true).map(([k,v]) => ({[k]:v}))
        );
        console.log(Object.keys(statuses));
        //console.log(reportDates);
        setSpinnerDisplay(true);
        pullData({
            variables: {
                startDate: reportDates['sdate'],
                endDate: reportDates['edate'],
                dealerToken: '',
                statuses: Object.keys(statuses).toString()
            }
        });
        
    };

    const startDateCapture = date => {
        console.log(date.format('YYYY-MM-DD'));
        let tempDate = reportDates;
        tempDate['sdate'] = date.format('YYYY-MM-DD');
        setReportDates(tempDate);
    }

    const endDateCapture = date => {
        console.log(date.format('YYYY-MM-DD'));
        let tempDate = reportDates;
        tempDate['edate'] = date.format('YYYY-MM-DD');
        setReportDates(tempDate);
    }

    
    const colNames = {'reportDate' : '', 'dealerId' : '', 'dealerName': '', 'dealerPhone': '', 'infoLeaseContractNo': '', 'sfdcId': '', 
    'cusName':'', 'sysAddress': '', 'contractStatus': '', 'counterSignDate': '', 
    'fundingSubmissionDate': '', 'depositDate': '', 'contractPrice': '', 'financingFees' : '',
    'disbursementAmt': '', 'pendingReason': '', 'disposalFlag':''};;

    const onCheckBoxChange = (colSelected) => {
        console.log(colSelected);
        setReportCols(colSelected);
    }

    const checkBoxList = [
        [
            {'id': 'reportDate', 'label':'Last Reporting Date'},
            {'id': 'dealerId', 'label':'Dealer ID'},
            {'id': 'dealerName', 'label':'Dealer Name'},
            {'id': 'dealerPhone', 'label':'Dealer Phone'},
            {'id': 'infoLeaseContractNo', 'label':'Info Lease Contract No'},
            {'id': 'sfdcId', 'label':'Customer Contract No'},  
        ],
        [
            {'id': 'cusName', 'label':'Customer Name'},
            {'id': 'sysAddress', 'label':'Customer Install Address'},
            {'id': 'contractStatus', 'label':'Contract Status'},
            {'id': 'counterSignDate', 'label':'Contract Counter Signed Date'},
            {'id': 'fundingSubmissionDate', 'label':'Funding Submission Date'},
            {'id': 'depositDate', 'label':'Disbursement Date'},
            
        ],
        [
            {'id': 'contractPrice', 'label':'Contract Price'},
            {'id': 'financingFees', 'label':'SunPower Financing Fees (9%)'},
            {'id': 'disbursementAmt', 'label':'Disbursement Amount'},
            {'id': 'pendingReason', 'label':'Pending Reason/Notes'},
            {'id': 'disposalFlag', 'label':'Disposal Flag'},
            /*{'id': 'state', 'label':'State'},
            {'id': 'zip', 'label':'Zip'},*/
        ],
        /*[
            {'id': 'maddress1', 'label':'Mailing Address1'},
            {'id': 'maddress2', 'label':'Mailing Address2'},
            {'id': 'mcity', 'label':'Mailing City'},
            {'id': 'mstate', 'label':'Mailing State'},
            {'id': 'mzip', 'label':'Mailing Zip'},
        ]*/
    ];

    const statusColNames = {'all':'', 'Funding Submitted':'', 'Funding Hold':'','Funded':'', 'Disbursed': ''};

    const onStatusCheckBoxChange = (colSelected) => {
        console.log(colSelected);
        setStatusCols(colSelected);
    }

    const statusCheckBoxList = [
        [
            {'id': 'all', 'label':'All Statuses'}
        ],
        [
            {'id': 'Funding Submitted', 'label':'Submitted for Funding'}
        ],
        [
            {'id': 'Funding Hold', 'label':'Funding Hold'}
        ],
        [
            {'id': 'Funded', 'label':'Funded'}
        ],
        [
            {'id': 'Disbursed', 'label':'Disbursed'}
        ]
    ];

    

    return (
        <Fragment>
            <LoadingOverlay
                active={spinnerDisplay}
                spinner
                text='Loading your report data...'
                >
            <NavWithRouter />
            <Jumbotron image="reporting" text="Reporting"/>
                <br />
            <Styles>
                
                
            <main>
                <div className="container">
	                <h2>Reporting</h2>	          
                    <p>Reports will be produced and downloaded as Excel</p>
                    <Form
                        name="report"
                        {...formItemLayout}
                        onFinish={onFinish}
                        >
                    <div className="card">
                        <div className="card-body">
                            <p><h6>Report Columns</h6></p>
                            <CheckBoxGrid
                                checkBoxList={checkBoxList}
                                onCheckBoxChange = {onCheckBoxChange}
                                colNames = {colNames}
                                showSelectAll={true}
                            />
                            <br />
                            <p><h6>Payment Status</h6></p>
                            <CheckBoxGrid
                                checkBoxList={statusCheckBoxList}
                                onCheckBoxChange = {onStatusCheckBoxChange}
                                colNames = {statusColNames}
                                showSelectAll={false}
                            />
                            
                            <div className="form-row bg-light p-2 mt-4">
                                <div className="col-md">
                                    <div className="form-group">
                                    <Form.Item label="Start Date" value="sdate">
                                        <DatePicker onChange={startDateCapture} />
                                    </Form.Item>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="form-group">
                                    <Form.Item label="End Date" value="edate">
                                        <DatePicker onChange={endDateCapture} />
                                    </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <br />
                            <Row>
                                <Col span={6} offset={10}>
                                <Button type="primary" htmlType="submit" shape="round" size="large">
                                    Export Report
                                </Button>
                                </Col> 
                            </Row>

                            
                        </div>
                    </div>
                    </Form>
                </div>
                <br />
                
            </main>
            </Styles>
            </LoadingOverlay>
            
        </Fragment>
    );
}

export default Report;