import React, { useState, useCallback, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import styled from 'styled-components';
import NavWithRouter from './NavigationBar';
import Jumbotron from './Jumbotron';
import { Alert, Container, Row, Col } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import { AgGridColumn, AgGridReact } from "@ag-grid-community/react";
import {AllCommunityModules} from "@ag-grid-community/all-modules";
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Footer from './Footer';

const Styles = styled.div`
    .ag-cell {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    label {
        font-weight: normal !important;
    }

    .div-percent-bar {
        display: inline-block;
        height: 20px;
        position: relative;
    }

    .div-percent-value {
        position: absolute;
        padding-left: 4px;
        font-weight: bold;
        font-size: 13px;
    }

    .div-outer-div {
        display: inline-block;
        height: 100%;
        width: 100%;
    }
`;

const query = gql`
  query retrieveDealerPaymentInformation($startDate: String, $endDate: String, $dealerToken: String) {
    retrieveDealerPaymentInformation(startDate: $startDate, endDate: $endDate, dealerToken: $dealerToken) {
        reportDate
        dealerId
        dealerName
        infoLeaseContractNo
        sfdcId
        cusName
        sysAddress
        contractStatus
        counterSignDate
        fundingSubmissionDate
        depositDate
        contractPrice
        financingFees
        disbursementAmt
        pendingReason
        disposalFlag
    }
  }
`;

const DealerPaymentTable = (props) => {
    let pageSize = 10;
    let icons = {
            columnRemoveFromGroup: '<i class="fa fa-times"/>',
            filter: '<i class="fa fa-filter"/>',
            sortAscending: '<i class="fa fa-long-arrow-alt-down"/>',
            sortDescending: '<i class="fa fa-long-arrow-alt-up"/>',
            groupExpanded: '<i class="far fa-minus-square"/>',
            groupContracted: '<i class="far fa-plus-square"/>'
        };
    const [api, setApi] = useState(null);
    const [rowCount, setRowCount] = useState(null);
    const [spinnerDisplay, setSpinnerDisplay] = useState(true);
    const [rowData, setRowData] = useState([]);
    const [quickFilterText, setquickFilterText] = useState(null);
    let {loading, data, error} = useQuery(query, {
        variables: { startDate: '', endDate: '', dealerToken: '' },
      });
    
    useEffect(() => {
        //if (loading) console.log(loading);
        
        if (!loading && !error && spinnerDisplay) {
            //console.log('data: ',data);
            let tempRowData = [];
            data.retrieveDealerPaymentInformation.map(dealerEntry => {
                let dealer = {reportDate:dealerEntry.reportDate, dealerId:dealerEntry.dealerId, dealerName: dealerEntry.dealerName, infoLeaseContractNo: dealerEntry.infoLeaseContractNo,
                sfdcId: dealerEntry.sfdcId, cusName: dealerEntry.cusName, sysAddress: dealerEntry.sysAddress, contractStatus: dealerEntry.contractStatus,
                counterSignDate: dealerEntry.counterSignDate, fundingSubmissionDate: dealerEntry.fundingSubmissionDate, depositDate: dealerEntry.depositDate, contractPrice: dealerEntry.contractPrice,
                financingFees: dealerEntry.financingFees, disbursementAmt: dealerEntry.disbursementAmt, pendingReason: dealerEntry.pendingReason,
                disposalFlag: dealerEntry.disposalFlag
            }
                
                tempRowData.push(dealer);
            });
            setRowData(tempRowData);
            setSpinnerDisplay(false);
            
        }
    })

    const onGridReady = (params) => {
        setApi(params.api);
        calculateRowCount();
    };

    const onCellClicked = (event) => {
        //console.log(event);
        //console.log('onCellClicked: ' + event.data.name + ', row ' + event.rowIndex+', mobile:'+event.data.mobile);
    };

    const onRowSelected = (event) => {
        //console.log('onRowSelected: ' + event.node.data.name);
    };

    const onQuickFilterText = (event) => {
        //this.setState({ quickFilterText: event.target.value });
        setquickFilterText(event.target.value);
    };

    const onRefreshData = () => {
        
    };

    const calculateRowCount = () => {
        if (rowData) {
            if (api) {
                const model = api.getModel();
                const totalRows = rowData.length;
                const processedRows = model.getRowCount();
                setRowCount(processedRows.toLocaleString() + ' / ' + totalRows.toLocaleString());
            } else {
                setRowCount(0);
            }   
        }
    };

    return (
        <Fragment>
            <LoadingOverlay
                active={spinnerDisplay}
                spinner
                text='Loading your content...'
                >
                <NavWithRouter />
                <Jumbotron image="reporting" text="Dealer Payment List"/>
                    <br />
                    <Container>
                        <Row>
                        <Col md={12}>
                            <Styles>
                                <div style={{ display: "inline-block", width: "100%", marginTop: 10, marginBottom: 10 }}>
                                    <div style={{ float: "left" }}>
                                    </div>
                                    <div style={{ float: "right", marginLeft: 20 }}>
                                        <label htmlFor="quickFilter">Quick Filter:&nbsp;</label>
                                        <input type="text" id="quickFilter" onChange={onQuickFilterText} placeholder="Type text to filter..." />
                                    </div>
                                </div>
                                <div style={{ height: 400, width: 1100 }} className="ag-theme-balham">
                                    <AgGridReact
                                        // listening for events
                                        onGridReady={onGridReady}
                                        onRowSelected={onRowSelected}
                                        onCellClicked={onCellClicked}
                                        onModelUpdated={calculateRowCount}
                                        pagination={true}
                                        paginationPageSize={pageSize}
                                        quickFilterText={quickFilterText}
                                        enableCellTextSelection={true}
                                        // binding to an object property
                                        icons={icons}

                                        // binding to array properties
                                        rowData={rowData}

                                        // register all modules (row model, csv/excel, row grouping etc)
                                        modules={AllCommunityModules}

                                        // no binding, just providing hard coded strings for the properties
                                        // boolean properties will default to true if provided (ie suppressRowClickSelection => suppressRowClickSelection="true")
                                        suppressRowClickSelection
                                        rowSelection="multiple"
                                        groupHeaders

                                        // setting grid wide date component
                                        //dateComponentFramework={DateComponent}

                                        // setting default column properties
                                        defaultColDef={{
                                            resizable: true,
                                            sortable: true,
                                            filter: true,
                                            //headerComponentFramework: SortableHeaderComponent,
                                            headerComponentParams: {
                                                menuIcon: 'fa-bars'
                                            }
                                        }}>
                                        <AgGridColumn headerName="Dealer Payment Information">
                                            <AgGridColumn headerName="Report Date" field="reportDate" width={110} filter="text" />
                                            <AgGridColumn headerName="Dealer ID" field="dealerId" width={120} filter="text" />
                                            <AgGridColumn headerName="Dealer Name" field="dealerName" width={130} filter="text" />
                                            <AgGridColumn headerName="Info Lease Contract No" field="infoLeaseContractNo" width={150} filter="text" />
                                            <AgGridColumn headerName="SFDC ID" field="sfdcId" width={120} filter="text" />
                                            <AgGridColumn headerName="Customer Name" field="cusName" width={120} filter="text" />
                                            <AgGridColumn headerName="System Address" field="sysAddress" width={200} filter="text" />
                                            <AgGridColumn headerName="Contract Status" field="contractStatus" width={120} filter="text" />
                                            <AgGridColumn headerName="Countersign Date" field="counterSignDate" width={80} filter="text" />
                                            <AgGridColumn headerName="Funding Submission Date" field="fundingSubmissionDate" width={80} filter="text" />
                                            <AgGridColumn headerName="Deposit Date" field="depositDate" width={80} filter="text" />
                                            <AgGridColumn headerName="Contract Price" field="contractPrice" width={80} filter="text" />
                                            <AgGridColumn headerName="SunPower Financing Fees (9%)" field="financingFees" width={80} filter="text" />
                                            <AgGridColumn headerName="Disbursement Amount" field="disbursementAmt" width={80} filter="text" />
                                            <AgGridColumn headerName="Pending Reason/Notes" field="pendingReason" width={80} filter="text" />
                                            <AgGridColumn headerName="Disposal Flag" field="disposalFlag" width={80} filter="text" />
                                        </AgGridColumn>
                                        
                                    </AgGridReact>
                                </div>
                            </Styles>
                        </Col>
                        </Row>
                        
                    </Container>
                    
                    <br />
                </LoadingOverlay>
            <Footer></Footer>

        </Fragment>
      );
}

export default DealerPaymentTable;