import React, { useState, useCallback, useEffect, Fragment } from 'react';
import NavWithRouter from './NavigationBar';
import Jumbotron from './Jumbotron';
import { Alert, Container, Row, Col } from 'reactstrap';
import Footer from './Footer';

const DealerACHUpdate = (props) => {
    return (
        <>
            <NavWithRouter />
            <Jumbotron image="home" text="Dealer ACH Update"/>
            <br></br>
            <Container>
                <p>Coming Soon...</p>
            </Container>
            <Footer></Footer>
        </>
    );
}

export default DealerACHUpdate;