import React, { useState, useCallback, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useQuery, useSubscription } from "@apollo/react-hooks";

import { CSVLink, CSVDownload } from "react-csv";
import LoadingOverlay from 'react-loading-overlay';
import { Alert, Container, Row, Col } from 'reactstrap';
import Footer from './Footer';
import NavWithRouter from './NavigationBar';
import Jumbotron from './Jumbotron';
import './ChaseACH.scss';
 
const csvData = [
  ["firstname", "lastname", "email"],
  ["Ahmed", "Tomi", "ah@smthing.co.com"],
  ["TransID", "PayeeID", "Account Number", "Routing Number","Payee Name"],
  [12345, 7776666, 999999999, 123456789122233, "Sush Veng"]
];

const query = gql`
  query generateACHFile {
    generateACHFile {
      header {
        indicator
        totCredit
        fileId
        creationdt
        creationtime
        totalTrans
        totCredit
        totDebit
        batchCount
      }
      batch {
        indicator
        classCode
        accountNumber
        sec
        companyDesc
        deliveryDate
        batchCredit
        batchDebit
        batchNo
        batchTrans
      }
      trans {
        indicator
        trxnCode
        routing
        accountNo
        trxnAmt
        payeeIDNo
        payeeName
        traceId
        addenda
      }
    }
  }
`;

const ChaseACH = () => {
  const [rowData, setRowData] = useState([]);
  let {loading, data, error} = useQuery(query);
  const [spinnerDisplay, setSpinnerDisplay] = useState(true);
  const [emptyDisplay, setEmptyDisplay] = useState(false);
  const headerOrder = ['indicator', 'fileId', 'creationdt', 'creationtime', 'totalTrans', 'totCredit', 'totDebit', 'batchCount'];
  const batchOrder = ['indicator', 'classCode', 'accountNumber', 'sec', 'companyDesc', 'deliveryDate', 'batchCredit', 'batchDebit', 'batchNo', 'batchTrans'];
  const transOrder= ['indicator', 'trxnCode', 'routing', 'accountNo', 'trxnAmt', 'payeeIDNo', 'payeeName', 'traceId', 'addenda'];

  useEffect(() => {
      if (loading) console.log(loading);
      
      if (!loading && !error && spinnerDisplay) {
          console.log('data: ',data);
          let tempRowData = [];
          tempRowData.push(['Indicator', 'File ID', 'File creation date', 'File creation time', 
          'Total trxns', 'Total credit amount', 'Total debit amount', 'Batch count']);
          let downloadData = true;
          data.generateACHFile['header'].map(header => {
            let temp = [];
            /*for (const [key, value] of Object.entries(header)) {
              //console.log(`${key}: ${value}`);
              if (key == 'totCredit' && value == 0) downloadData = false;
              if (key != '__typename') temp.push(value);
            }*/
            headerOrder.map(headerCol => {
              if (headerCol == 'totCredit' && header[headerCol] == 0) downloadData = false;
              const headerVal = (typeof header[headerCol] == 'string') ? header[headerCol].replace(/["]+/g, '') : header[headerCol];
              temp.push(headerVal);
            });
            console.log(temp);
            tempRowData.push(temp);
          });
          tempRowData.push(['Indicator', 'Service Class', 'Chase Acct', 'SEC Code', 
          'Entry Description', 'Delivery by date', 'Batch credit amount', 'Batch debit amount', 'Batch number',
          'Trxn in batch']);
          data.generateACHFile['batch'].map(batch => {
            let temp = [];
            /*for (const [key, value] of Object.entries(batch)) {
              //console.log(`${key}: ${value}`);
              if (key != '__typename') temp.push(value);
            }*/
            batchOrder.map(batchCol => {
              const batchVal = (typeof batch[batchCol] == 'string') ? batch[batchCol].replace(/["]+/g, '') : batch[batchCol];
              temp.push(batchVal);
            });
            tempRowData.push(temp);
          });
          tempRowData.push(['Indicator', 'Trxn code', 'Routing Number', 'Account Number', 
          'Trxn amount', 'ID number', 'Payee name', 'Trxn ID', 'Addenda']);
          data.generateACHFile['trans'].map(trans => {
            let temp = [];
            /*for (const [key, value] of Object.entries(trans)) {
              //console.log(`${key}: ${value}`);
              if (key != '__typename') temp.push(value);
            }*/
            transOrder.map(transCol => {
              const transVal = (typeof trans[transCol] == 'string') ? trans[transCol].replace(/["]+/g, '') : trans[transCol];
              temp.push(transVal);
            });
            
            tempRowData.push(temp);
            if (!downloadData)  {
              tempRowData = [];
              setEmptyDisplay(true);
            } else setEmptyDisplay(false);
          });
          /*data.generateACHFile.map(paymentEntry => {
              
              
          });*/
          setRowData(tempRowData);
          setSpinnerDisplay(false);
          
      }
  })
  
  const d = new Date();
  const chaseFileName = "NachaCSV"+d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate()+'_'+d.getHours()+'-'+d.getMinutes()+'.csv';

    return(
      <>
        <LoadingOverlay
          active={spinnerDisplay}
          spinner
          text='Loading your content...'
          >
            <NavWithRouter />
            <Jumbotron image="reporting" text="ACH File"/>
            <br />
            <Container>
                <Row>
                  <Col md={12}>
                    <Alert color="info" style={{ display: ((emptyDisplay) ? 'block' : 'none') }}>
                      There is no pending data to download.
                    </Alert>
                    {!emptyDisplay ? <CSVLink data={rowData} filename={chaseFileName} enclosingCharacter={``} className="button">Download ACH File</CSVLink> : null}
                    
                  </Col>
                </Row>
            </Container>
            <Footer></Footer>
          </LoadingOverlay>
      </>
    );
}

export default ChaseACH;