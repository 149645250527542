import gql from 'graphql-tag';

const paymentsUploaderSubscription = gql`
    subscription dealerFileUpdateStatus {
        dealerFileUpdateStatus {
            type
            message
        }
    }
`;

export default paymentsUploaderSubscription;