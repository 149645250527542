import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { Collapse } from 'antd';
import NavWithRouter from '../MyAccount/NavigationBar';
import Jumbotron from '../MyAccount/Jumbotron';
import { Container } from 'react-bootstrap';
import Footer from './Footer';

const { Panel } = Collapse;

const Faqs = (props) => {
    return (
        <>
            <NavWithRouter />
            <Jumbotron image="faqs" text="Frequently Asked Questions"/>
            <Container>
                    <br></br>
                    <Collapse>
                    <Panel header="What do the payment statuses mean?" key="1">
                        <ul>
                            <li>Funding Submitted – means request has been sent to lender and SunPower is awaiting response or approval.</li>
                            <li>Funding Hold – Lender or SunPower has stopped the release off funds to Dealer for more info needed.</li>
                            <li>Funded  - means SunPower has received the funds and sent to BPS for release.</li>
                            <li>Disbursement – means BPS has processed the ACH payment.</li>
                            <li>Disbursement Hold – This means you either must confirm the amount of the ACH test or you have requested that the Account Number and Routing Number be changed and you have ACH payment pending.</li>
                        </ul>
                    </Panel>
                    <Panel header="Once a payment is in Funded status, how long will it take to receive my payment?" key="2">
                        <p>Payments are processed up to one business day from the date funded status is received.</p>
                    </Panel>
                    <Panel header="If I do not agree with the amount disbursed, who do I contact?" key="3">
                        <p>Contact SunPower via email at <a href='mailto:dpm@sunpower.com'>dpm@sunpower.com</a></p>
                    </Panel>
                    <Panel header="What if I do not see a payment in Process on this website?" key="4">
                        <p>Contact SunPower via email at <a href='mailto:dpm@sunpower.com'>dpm@sunpower.com</a></p>
                    </Panel>
                </Collapse>
            </Container>
            <Footer></Footer>
        </>
    );
}

export default Faqs;