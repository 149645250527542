import React from 'react';
import { Container, Grid, Row,Col } from 'react-bootstrap';

import { authenticationService } from '../../_services';

import styled from 'styled-components';
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import gql from 'graphql-tag';
import { graphql} from 'react-apollo';
import LoadingOverlay from 'react-loading-overlay'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Alert } from 'reactstrap';
import 'antd/dist/antd.css';


const Styles = styled.div`
    .navbar {
        background-color: #fff;
    }

    a, .navbar-brand, .navbar-nav .nav-link {
        color: #6c757d;

        &:hover {
            color: hsla(0,0%,0%,1.00);
            border-bottom: #F18B21 2px solid;
        }
        &:active {
            color: hsla(0,0%,0%,1.00);
            border-bottom: #F18B21 2px solid;
        }
    }

    .nav-link.tab-title {
        color: #858585;
        background-color: #EEEEEE;
    }

    .nav-link.tab-title:hover {
        color: #E2620E;
    }

    .nav-link.tab-title.active {
        color: #E2620E;
    }
    .jumbo {
        background-image: url("images/Home - My Account.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        min-height: 30em;
    }
    .OrangeBtn {
        font-weight: 500;
        background-color: #F18B21;
        color: hsla(0,0%,100%,1.00);
    }
    .OrangeBtn:hover {
        background-color: #E2620E;
        color: hsla(0,0%,100%,1.00);
    }
    .buttonOrange {
        background-color: #F18B21;
        background-color: #E2620E;
        color: hsla(0,0%,100%,1.00);
        padding: 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        border-radius: 12px;
    }
    .buttonOrange:hover {
        background-color: #E2620E;
        background-color: #E2620E;
        color: hsla(0,0%,100%,1.00);
        padding: 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        border-radius: 12px;
    }

    .square_btn{
        display: inline-block;
        padding: 7px 20px;
        border-radius: 25px;
        text-decoration: none;
        hsla(0,0%,100%,1.00);
        background-image: -webkit-linear-gradient(45deg, #FFC107 0%, #ff8b5f 100%);
        background-image: linear-gradient(45deg, #FFC107 0%, #ff8b5f 100%);
        transition: .4s;
    }
    
    .square_btn:hover {
        display: inline-block;
        padding: 7px 20px;
        border-radius: 25px;
        text-decoration: none;
        hsla(0,0%,100%,1.00);
        background-image: -webkit-linear-gradient(45deg, #FFC107 0%, #ff8b5f 100%);
        background-image: linear-gradient(45deg, #FFC107 0%, #ff8b5f 100%);
        transition: .4s;
    }

    div.alert {
        color:red;
    }
`;

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        // redirect to home if already logged in
        if (authenticationService.currentUserValue) { 
            this.props.history.push('/');
        }
        this.onFinish = this.onFinish.bind(this);
        this.state = {
            spinnerDisplay: false,
            errorMessage: false
        }
    }
    onFinish(values) {
        //console.log('Received values of form: ', values);
        this.setState({spinnerDisplay: true});
        authenticationService.login(values.email, values.password)
                            .then(
                                user => {
                                    if (localStorage.getItem('resetPwdFlag')==='true') {
                                        console.log('reset redirect');
                                        this.props.history.push({ pathname: "/resetPwd" });
                                    } else {
                                        const { from } = this.props.location.state || { from: { pathname: "/uploader" } };
                                        this.props.history.push(from);
                                    }
                                },
                                error => {
                                    console.log(error);
                                    this.setState({spinnerDisplay: false, errorMessage: error});
                                    
                                }
                            );
        /*this.props.mutate({
            variables: { 
              email: values.email, 
              password: values.password
            }
          }).then((data) => { window.location.href = '/login'; } )
          .catch((error) => {console.log(error)});*/
        
    }

    render() {
        return (
            <LoadingOverlay
                active={this.state.spinnerDisplay}
                spinner
                text='Loading your content...'
                >
                <Styles>
                    <Navbar expand="lg">
                    <Navbar.Brand href="/"><img src="images/SunPower.png" width="180px" alt="logo"/></Navbar.Brand>
                    </Navbar>
                    <Container className="jumbo">
                        <Row>
                            <Col lg={6} md={{ size: 6, offset: 3 }} className="m-6 shadow-sm p-6">
                                <br />
                                <br />
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={{ size: 6, offset: 3 }} className="m-6 shadow-sm p-6">
                            <div className="card align-self-center">
                                <div className="card-header">
                                    <h3 className="text-center">Log In</h3>
                                </div>
                                <div className="card-body">
                                <Alert color="danger" style={{ display: ((this.state.errorMessage) ? 'block' : 'none') }}>
                                    {this.state.errorMessage}
                                </Alert>
                                    <Form
                                    name="normal_login"
                                    className="login-form"
                                    initialValues={{ remember: true }}
                                    onFinish={this.onFinish}
                                    size="large"
                                    >
                                    <div class="form-group">
                                    <Form.Item
                                        name="email"
                                        rules={[{ required: true, message: 'Please input your Email!' }]}
                                    >
                                        <Input prefix={<UserOutlined  />} placeholder="Email" autoComplete='off' />
                                    </Form.Item>
                                    </div>
                                    <div class="form-group">
                                    <Form.Item
                                        name="password"
                                        rules={[{ required: true, message: 'Please input your Password!' }]}
                                    >
                                        <Input
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        type="password"
                                        placeholder="Password"
                                        autoComplete='off'
                                        />
                                    </Form.Item>
                                    </div>
                                    
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className="square_btn" shape="round" size="large">
                                        Log in
                                        </Button>
                                    </Form.Item>
                                    <a className="login-form-forgot" href="/forgotpwd">
                                        Forgot password
                                        </a>
                                    
                                    </Form>
                                </div>
                            </div>
                            
                                
                                
                            
                            </Col>
                        
                        </Row>	
                    </Container>
                </Styles>
            </LoadingOverlay>
        );
    }
}

const LOGIN = gql`
  mutation Login($email: String, $password: String) {
    login(email: $email, password: $password) {
      id
      email
    }
  }
`;
//export default graphql(LOGIN)(LoginPage);
export default LoginPage;