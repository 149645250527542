import React from 'react';
import { Jumbotron as Jumbo, Container,  Row,Col } from 'react-bootstrap';
import styled from 'styled-components';
import { authenticationService } from '../../_services';
import { history } from '../../_helpers';

const Styles = styled.div`
   .divider-light {
	background-color: #E0E0E5;
	height: 2px;
	margin-bottom: 14px;
   }
   .BlueBtnOutline{
	font-weight: 500;
	border: 1px solid #0076BE;
	color: #0076BE;
    }

    .BlueBtnOutline:hover{
        background-color:#0076BE;
        color: #FFFFFF;
    }

    .BlueBtnOutline:focus{
        background-color:#39607A;
        color: #FFFFFF;
    }

    .BlueBtnOutline:active{
        background-color:#39607A;
        color: #FFFFFF;
    }
  .home {
    background-image: url("images/Home - My Account.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    min-height: 20em;
    position: relative;
  }
  .uploader {
    background-image: url("images/Payment Uploader.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    min-height: 20em;
    position: relative;
  }
  .register {
    background-image: url("images/ACH Credit Registration.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
    min-height: 20em;
    position: relative;
  }
  .reporting {
    background-image: url("images/Reporting.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
    min-height: 20em;
    position: relative;
  }
  .cusservice {
    background-image: url("images/Customer Service.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
    min-height: 20em;
    position: relative;
  }
  .faqs {
    background-image: url("images/FAQs.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
    min-height: 20em;
    position: relative;
  }
  .solarcard-dark {
    background-color: hsla(0,0%,20%,0.70);
    color: hsla(0,0%,100%,1.00);
    height: 150px;
    width: 600px;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
  }

  .text-position {
      padding-top: 60px;
  }
  
`;

const Jumbotron = ({image, text}) => {
    const handleLogout = () => {
      authenticationService.logout();
      history.push('/login');
      window.location='/login';
    }

    return (
      <Styles>
      <Container className="divider-light"></Container>
      <Container className={image}>
          <Row className="solarcard-dark">
              <Col>
                <h4 className="text-center text-white font-weight-bolder text-position">{text}</h4>
              </Col>
          
          </Row>	
      </Container>
    </Styles>
    );
}

export default Jumbotron;