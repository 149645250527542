import gql from 'graphql-tag';

const dealerpPaymentsUploaderSubscription = gql`
    subscription dealerPaymentsFileUpdateStatus {
        dealerPaymentsFileUpdateStatus {
            type
            message
        }
    }
`;

export default dealerpPaymentsUploaderSubscription;